import React from 'react';
import logo from '../logo.png';
import { initiateLogin } from '../api/userApi';
import './Navbar.css';

const Navbar = () => {
  const handleAuth = () => {
    // Redirect to the Azure AD B2C login flow
    // window.location.href = 'https://backend.tennetclub.ca/api/users/login/';
    initiateLogin();
  };

  return (
    <nav className="navbar">
      <img src={logo} className="navbar-logo" alt="Logo" />
      <div className="navbar-links">
        {/* <button onClick={handleAuth} className="login-button">Login / Sign Up</button> */}
      </div>
    </nav>
  );
};

export default Navbar;
