import React, { useContext } from 'react';
import { SearchContext } from '../context/SearchContext';
import CourtResultItem from './CourtResultItem';
import './CourtResults.css'; // Ensure you have the correct path to your CSS file

const CourtResults = () => {
  const { searchResults } = useContext(SearchContext);

  if (!searchResults) {
    return <div className="loading">SEARCHING...</div>;
  }

  const courts = searchResults;

  const groupCourtsByClub = (courts) => {
    const grouped = {};
    courts.forEach(court => {
      if (!grouped[court.club_name]) {
        grouped[court.club_name] = {
          club_name: court.club_name,
          courts: [],
        };
      }
      grouped[court.club_name].courts.push(court);
    });
    return Object.values(grouped);
  };
  
  const courtsGroupedByClub = groupCourtsByClub(courts);

  return (
    <div className="court-results-container">
      {courtsGroupedByClub.length > 0 ? (
        courtsGroupedByClub.map((clubData, index) => (
          <CourtResultItem key={index} clubData={clubData} />
        ))
      ) : (
        <p className="no-results-message">No courts found for the selected date.</p>
      )}
    </div>
  );
};

export default CourtResults;
