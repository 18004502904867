import axiosInstance from '../axiosInstance';

export const uploadCSV = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file); // 'file' is the field name expected in backend

    const response = await axiosInstance.post('/club-management/upload-csv/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading CSV:', error);
  }
};
