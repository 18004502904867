import React, { useState, useEffect } from 'react';
import { getCourtCalendar } from '../api/CourtCalendarApi/courtCalendarApi';
import CourtCalendar from './CourtCalendar';
import { useParams } from 'react-router-dom';

function AdminDashboard () {
    let { courtId } = useParams();
    const [courtCalendar, setCourtCalendar] = useState([]);

    useEffect(() => {
        const fetchCourtCalendar = async () => {
            const calendarData = await getCourtCalendar(courtId);
            setCourtCalendar(calendarData.filter(item => item.is_booked));
        };

        fetchCourtCalendar();
    }, [courtId]);

    return (
        <div>
            <h1>Admin Dashboard</h1>
            <CourtCalendar calendarData={courtCalendar} />
        </div>
    );
}

export default AdminDashboard;
