import React from 'react';

const CalendarDay = ({ date, bookings }) => {
    return (
        <div>
            <h2>{date}</h2>
            {bookings.map(booking => (
                <div key={booking.id}>
                    <p>{booking.start_time} - {booking.end_time}</p>
                    <p>{booking.booking_details.lesson || `Booked by: ${booking.booking_details.user}`}</p>
                </div>
            ))}
        </div>
    );
};

export default CalendarDay;
