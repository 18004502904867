import React, { useState, useEffect } from 'react';
import { createCourt } from '../api/ClubManagementApi/courtApi';
import { createCourtPricing } from '../api/ClubManagementApi/courtPricingApi';
import './CourtForm.css';

const daysOfWeek = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
];

const CourtForm = ({ show, onClose, onCourtAdded }) => {
  const [courtName, setCourtName] = useState('');
  const [status, setStatus] = useState('available');
  const [selectedDays, setSelectedDays] = useState(new Set());
  const [operatingHours, setOperatingHours] = useState({});
  const [pricingWindows, setPricingWindows] = useState([]);
  const [error, setError] = useState('');
  const [copyDay, setCopyDay] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedDays((prevSelectedDays) => {
      prevSelectedDays.forEach(day => {
        if (!operatingHours[day]) {
          setOperatingHours(prevHours => ({
            ...prevHours,
            [day]: { open: '', close: '' }
          }));
        }
      });
      return prevSelectedDays;
    });
  }, [selectedDays, operatingHours]);

  const handleCopyPricing = (dayToCopyTo) => {
    if (copyDay && pricingWindows[copyDay]) {
      setPricingWindows(prevPricing => ({
        ...prevPricing,
        [dayToCopyTo]: [...prevPricing[copyDay]],
      }));
    }
  };

  const sortDaysOfWeek = (days) => {
    const order = daysOfWeek
    return days.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  };

  const sortedSelectedDays = sortDaysOfWeek(Array.from(selectedDays));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'courtName') {
      setCourtName(value);
    } else if (name === 'status') {
      setStatus(value);
    }
  };

  const handleDaySelection = (day) => {
    setSelectedDays((prevSelectedDays) => {
      const newSelectedDays = new Set(prevSelectedDays);
      if (newSelectedDays.has(day)) {
        newSelectedDays.delete(day);
      } else {
        newSelectedDays.add(day);
      }
      return newSelectedDays;
    });
  };

  // const handleOperatingHoursChange = (day, openOrClose, value) => {
  //   setOperatingHours((prevHours) => ({
  //     ...prevHours,
  //     [day]: {
  //       ...prevHours[day],
  //       [openOrClose]: value,
  //     },
  //   }));
  // };

  const addPricingWindow = (day) => {
    setPricingWindows(prevPricing => ({
      ...prevPricing,
      [day]: [...(prevPricing[day] || []), { time_window_start: '', time_window_end: '', price: '' }],
    }));
  };

  const handlePricingChange = (day, index, event) => {
    const { name, value } = event.target;
    if (name === 'price') {
      // Cast to a number and ensure it's not negative
      const numValue = Math.max(0, parseFloat(value));
      updatePricing(day, index, numValue, name);
    } else {
      updatePricing(day, index, value, name);
    }
  };  
  
  const updatePricing = (day, index, newValue, fieldName) => {
    setPricingWindows(prevPricing => {
      const updatedDayPricing = prevPricing[day].map((pricing, idx) => {
        if (idx === index) {
          return { ...pricing, [fieldName]: newValue };
        }
        return pricing;
      });
  
      return { ...prevPricing, [day]: updatedDayPricing };
    });
  };  

  const removePricingWindow = (day, index) => {
    setPricingWindows(prevPricing => ({
      ...prevPricing,
      [day]: prevPricing[day].filter((_, idx) => idx !== index),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    // Process pricing windows to extract operating hours
    const processedOperatingHours = {};
    Object.entries(pricingWindows).forEach(([day, windows]) => {
      const openTimes = windows.map(w => w.time_window_start);
      const closeTimes = windows.map(w => w.time_window_end);
      processedOperatingHours[day] = {
        open: openTimes.sort()[0], // Earliest start time
        close: closeTimes.sort()[closeTimes.length - 1] // Latest end time
      };
    });

    const courtData = {
      name: courtName,
      operating_hours: processedOperatingHours,
      status: status,
    };

    try {
      const courtResponse = await createCourt(courtData);
      onCourtAdded(courtResponse);
  
      const pricingPromises = Object.entries(pricingWindows).flatMap(([day, windows]) =>
        windows.map(window =>
          createCourtPricing({
            day_of_week: day,
            court: courtResponse.id,
            time_window_start: window.time_window_start,
            time_window_end: window.time_window_end,
            price: window.price,
          })
        )
      );
  
      await Promise.all(pricingPromises);
      setIsLoading(false);
      onClose(); // Close the modal after successful submission
    } catch (error) {
      setError('An error occurred while creating court or pricing.');
      setIsLoading(false);
    }
  };

  if (!show) {
    return null;
  }
  
  return (
    <>
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className={`modal-backdrop ${isLoading ? 'modal-blur' : ''}`}>
        <div className="modal-backdrop">
          <div className="modal">
            <form onSubmit={handleSubmit} className="court-form">
              <h1>Add New Court</h1>
      
              <div className="form-group">
                <label>Court Name:</label>
                <input
                  type="text"
                  name="courtName"
                  value={courtName}
                  onChange={handleInputChange}
                  required
                />
              </div>
      
              <div className="form-group">
                <label>Status:</label>
                <select
                  name="status"
                  value={status}
                  onChange={handleInputChange}
                  required
                >
                  <option value="available">Available</option>
                  <option value="maintenance">Under Maintenance</option>
                  <option value="closed">Closed</option>
                </select>
              </div>
      
              <div className="form-group">
                <label>Operating Days:</label>
                {daysOfWeek.map((day) => (
                  <div key={day} className="checkbox-grid">
                    <label>{day}</label>
                    <input
                      type="checkbox"
                      checked={selectedDays.has(day)}
                      onChange={() => handleDaySelection(day)}
                    />
                  </div>
                ))}
              </div>
      
              {/* Operating Hours and Pricing for Each Day */}
              {sortedSelectedDays.map((day) => (
                <div key={day} className="day-section">
                  {/* <h3>{day} Operating Hours</h3> */}
                  {/* <div className="day-hours">
                    <div className="form-group">
                      <label>Open Time:</label>
                      <input
                        type="time"
                        value={operatingHours[day]?.open || ''}
                        onChange={(e) => handleOperatingHoursChange(day, 'open', e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Close Time:</label>
                      <input
                        type="time"
                        value={operatingHours[day]?.close || ''}
                        onChange={(e) => handleOperatingHoursChange(day, 'close', e.target.value)}
                        required
                      />
                    </div>
                  </div> */}

                  {/* Pricing Section for the Day */}
                  <div className="day-pricing-section">
                    <h3>{day} Pricing</h3>

                    {/* Add a dropdown to select the day from which to copy */}
                    <select className='copy-pricing-dropdown' value={copyDay} onChange={(e) => setCopyDay(e.target.value)}>
                      <option value="">Copy from...</option>
                      {sortedSelectedDays.filter(d => d !== day && pricingWindows[d]).map(d => (
                        <option key={d} value={d}>{d}</option>
                      ))}
                    </select>
                    <button type="button" className="copy-pricing-button" onClick={() => handleCopyPricing(day)}>Copy</button>

                    {(pricingWindows[day] || []).map((pricing, index) => (
                      <div className="pricing-window" key={`${day}-${index}`}>
                        {/* Pricing Time Inputs */}
                        <div className="form-group">
                          <label>Start Time:</label>
                          <input
                            type="time"
                            name="time_window_start"
                            value={pricing.time_window_start}
                            min={operatingHours[day]?.open || ''}
                            max={operatingHours[day]?.close || ''}
                            onChange={(e) => handlePricingChange(day, index, e)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>End Time:</label>
                          <input
                            type="time"
                            name="time_window_end"
                            value={pricing.time_window_end}
                            min={operatingHours[day]?.open || ''}
                            max={operatingHours[day]?.close || ''}
                            onChange={(e) => handlePricingChange(day, index, e)}
                            required
                          />
                        </div>
                        {/* Pricing Fee Input */}
                        <div className="form-group">
                          <label>Fee:</label>
                          <input
                            type="number"
                            name="price"
                            value={pricing.price}
                            onChange={(e) => handlePricingChange(day, index, e)}
                            required
                          />
                        </div>
                        <button
                          type="button"
                          className="remove-window-button"
                          onClick={() => removePricingWindow(day, index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="add-window-button"
                      onClick={() => addPricingWindow(day)}
                    >
                      Add Pricing Window for {day}
                    </button>
                  </div>
                </div>
              ))}

              {/* Submit and Close Buttons */}
              <button type="submit" className="submit-button">Submit</button>
              {error && <p className="error-message">{error}</p>}
              <button className="close-button" onClick={onClose}>Close</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );  
};

export default CourtForm;
