import axiosInstance from '../axiosInstance';

// create a court
export const createCourt = async (courtData) => {
  try {
    const response = await axiosInstance.post('/club-management/courts/', courtData);
    return response.data;
  } catch (error) {
    console.error('Error creating court:', error);
  }
};

// list courts
export const getCourts = async () => {
    try {
      const response = await axiosInstance.get('/club-management/courts/');
      return response.data;
    } catch (error) {
      console.error('Error fetching courts:', error);
    }
  };

// update a court
export const updateCourt = async (courtId, updatedData) => {
    try {
      const response = await axiosInstance.put(`/club-management/courts/${courtId}/`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating court:', error);
    }
  };

// delete a court
export const deleteCourt = async (courtId) => {
    try {
      const response = await axiosInstance.delete(`/club-management/courts/${courtId}/`);
      return response.status;
    } catch (error) {
      console.error('Error deleting court:', error);
    }
  };
  
// patch a court
export const patchCourt = async (courtId, patchData) => {
    try {
      const response = await axiosInstance.patch(`/club-management/courts/${courtId}/`, patchData);
      return response.data;
    } catch (error) {
      console.error('Error patching court:', error);
    }
  };
  