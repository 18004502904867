import axiosInstance from '../axiosInstance';

// create court booking
export const createCourtBooking = async (bookingData) => {
  try {
    const response = await axiosInstance.post('/bookings/create/court/', bookingData);
    return response.data;
  } catch (error) {
    console.error('Error creating court booking:', error);
  }
};

// create lesson booking
export const createLessonBooking = async (bookingData) => {
    try {
      const response = await axiosInstance.post('/bookings/create/lesson/', bookingData);
      return response.data;
    } catch (error) {
      console.error('Error creating lesson booking:', error);
    }
  };

// delete court booking
export const deleteCourtBooking = async (bookingId) => {
    try {
      const response = await axiosInstance.delete(`/bookings/delete/court/${bookingId}/`);
      return response.status;
    } catch (error) {
      console.error('Error deleting court booking:', error);
    }
  };

// delete lesson booking
export const deleteLessonBooking = async (bookingId) => {
    try {
      const response = await axiosInstance.delete(`/bookings/delete/lesson/${bookingId}/`);
      return response.status;
    } catch (error) {
      console.error('Error deleting lesson booking:', error);
    }
  };

// search available courts
export const searchCourts = async (queryParams) => {
    try {
      const response = await axiosInstance.get('/bookings/search/courts', { params: queryParams });
      return response.data;
    } catch (error) {
      console.error('Error searching courts:', error);
    }
  };

// search available lessons
export const searchLessons = async (queryParams) => {
    try {
      const response = await axiosInstance.get('/bookings/search/lessons', { params: queryParams });
      return response.data;
    } catch (error) {
      console.error('Error searching lessons:', error);
    }
  };
  