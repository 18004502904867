import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8000/api',
  baseURL: 'https://www.backend.tennetclub.ca/api',
});

// Manually set the token for development testing
// const YOUR_HARD_CODED_TOKEN = 'eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL3Rlbm5ldGNsdWJiMmMuYjJjbG9naW4uY29tLzE1NWYwOTRkLTg2NGMtNDI5MC1hMTJlLWRmMjM2NDEwMjhiNy92Mi4wLyIsInN1YiI6IjVlN2ZlOTQ3LTdlOWYtNGU3ZS05NTkxLWM5ZGI1MGY3YWE1MCIsImF1ZCI6ImI0OGE3N2ZkLTM4MGItNGRkNS1iYzNjLTQwNzAzMjI5Yzc5NyIsImV4cCI6MTcwNzM0Mjc5Niwibm9uY2UiOiJkZWZhdWx0Tm9uY2UiLCJpYXQiOjE3MDczMzkxOTYsImF1dGhfdGltZSI6MTcwNzMzOTE5NiwiaWRwX2FjY2Vzc190b2tlbiI6InlhMjkuYTBBZkJfYnlDZ2l2QTdDZVpTUUZKX3pUR0J3TncxUWhXMDBvN2ZoYmZDWXVRTWdwX19DY214YUYwZ1o3T1BWSUM3VTlqazIzVFE5X08wNXpyWVBHa3FKanhZcjA1NFVsdWdCX0o1Z2F6YnNQQVFRaTIxdXNHU0FxZUtiVW03dUZhRzNGeW1DTHkzTHFleEszWkdMd09aTjI1MUVZVlJlNVlNM3BwTWFDZ1lLQWRvU0FSQVNGUUhHWDJNaWlCdUp1a3lfWUgtQm5OUi0xWERZcXcwMTcxIiwiZ2l2ZW5fbmFtZSI6Ik1vZWluIiwiZmFtaWx5X25hbWUiOiJLYXplbWkiLCJuYW1lIjoiTW9laW4gS2F6ZW1pIiwiaWRwIjoiZ29vZ2xlLmNvbSIsIm9pZCI6IjVlN2ZlOTQ3LTdlOWYtNGU3ZS05NTkxLWM5ZGI1MGY3YWE1MCIsImV4dGVuc2lvbl9DbHVibmFtZSI6IlRoZSBDb3F1aXRsYW0gVGVubmlzIENlbnRyZSIsInN0cmVldEFkZHJlc3MiOiIxNjUwIEZvc3RlciBBdmUiLCJjaXR5IjoiQ29xdWl0bGFtIiwic3RhdGUiOiJCQyIsImNvdW50cnkiOiJDYW5hZGEiLCJwb3N0YWxDb2RlIjoiVjNKIDdYMSIsImV4dGVuc2lvbl9QaG9uZU51bWJlciI6IjYwNDUxODUzNTciLCJlbWFpbHMiOlsibW9laW4ua2F6ZW1pbWsyMDE2QGdtYWlsLmNvbSJdLCJ0ZnAiOiJCMkNfMV9hZG1pbl9zaWdudXBzaWduaW4iLCJuYmYiOjE3MDczMzkxOTZ9.Cnljpauo2qWoyv9qYdc2myMk4l2q03VuJHORjHcLlqYXfkS-CmpUE0SJfKhDUi2h6Y-3aX58OGKc05e2OEFUezjVfyQxe1CROsmOArnkO7krhXKrla03WkF4bNojKEfPsiFbPN_nzIskHkc7UEfv6y7Lr-h-Fhz5jKozVp_JbPwIwbI-R-PG-nAUiaflxguGhisEi9TnPi7PjyHV67A2g8IxurK23mJ0A0zVsbZPRhSHOd_tnpFF19MCOoVeT4uoAeDnbphdPKr6Ry4IDGudTpWImrR46hJPx797YEbhfyiYVvA3c3zYapUt8D3OKCztvEYJT77ihmMFpefJW8zyJw';
// axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${YOUR_HARD_CODED_TOKEN}`;

axiosInstance.interceptors.request.use(config => {
  const token = sessionStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
