import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getClubs } from '../api/ClubManagementApi/clubApi';
import { uploadCSV } from '../api/ClubManagementApi/memberApi';
import { getCourts } from '../api/ClubManagementApi/courtApi';
import './ClubProfile.css'; // Ensure you import your CSS styles
import CourtsList from './CourtsList'; // Component to list courts
import LessonsList from './LessonsList'; // Component to list lessons
import LessonForm from './LessonForm';
import CourtForm from './CourtForm';

const ClubProfile = () => {
  const [clubDetails, setClubDetails] = useState(null);
  const [logo, setLogo] = useState(null);
  const [membersFile, setMembersFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [showCourtForm, setShowCourtForm] = useState(false);
  const [showLessonForm, setShowLessonForm] = useState(false);
  const [courts, setCourts] = useState([]);

  // Function to add a new court to the list
  const addNewCourt = (newCourt) => {
    setCourts(prevCourts => [...prevCourts, newCourt]);
  };

  // Function to toggle court form visibility
  const toggleCourtForm = () => {
    setShowCourtForm(!showCourtForm);
  };

  // Function to toggle lesson form visibility
  const toggleLessonForm = () => {
    setShowLessonForm(!showLessonForm);
  };

  useEffect(() => {
    const fetchClubDetails = async () => {
      try {
        const data = await getClubs();
        if (data && data.length > 0) {
          setClubDetails(data[0]);
        } else {
          setError('No club details found.');
        }
      } catch (error) {
        setError('Error fetching club details: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClubDetails();
  }, []);

  useEffect(() => {
    // Function to fetch courts
    const fetchCourts = async () => {
      // Fetch courts and update state
      const response = await getCourts();
      setCourts(response || []);
    };

    fetchCourts();
  }, []);

  const handleLogoUpload = (event) => {
    setLogo(event.target.files[0]);
  };

  const handleMembersFileUpload = (event) => {
    setMembersFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // If you want to show loading during the operation

    // Logic for handling logo upload would be similar to members CSV upload

    if (membersFile) {
      try {
        await uploadCSV(membersFile);
      } catch (error) {
        // Handle errors if the upload fails
        console.error('Error uploading CSV:', error);
        setError('Failed to upload member list.');
      }
    }

    setLoading(false); // Stop showing loading
  };

  if (loading) return <div>Loading your club details...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!clubDetails) return <div>No club details available.</div>;

  return (
    <div className="club-profile-container">
      <div className="club-content-wrapper">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Details</Tab>
            <Tab>Courts</Tab>
            <Tab>Lessons</Tab>
          </TabList>
          <TabPanel>
            <form onSubmit={handleSubmit} className="club-profile-form">
              <h1>Club Profile</h1>
              <div>
                <label>Name: {clubDetails.name}</label>
              </div>
              <div>
                <label>Address: {clubDetails.address}</label>
              </div>
              {/* <div>
                <label>Description: {clubDetails.description}</label>
              </div> */}
              {/* Logo upload input */}
              <label htmlFor="club-logo">Club Logo:</label>
              <input type="file" id="club-logo" onChange={handleLogoUpload} accept="image/png, image/jpeg" />
              {/* Members file upload input */}
              <label htmlFor="club-members">Club Members:</label>
              <input type="file" id="club-members" onChange={handleMembersFileUpload} accept=".csv" />
              {/* Submit button */}
              {/* <button type="submit" className="submit-button">Submit</button> */}
            </form>
          </TabPanel>
          <TabPanel>
            <CourtsList />
            <button onClick={toggleCourtForm} className="add-new-button">Add New Court</button>
            {showCourtForm && (
              <CourtForm
                show={showCourtForm}
                onClose={toggleCourtForm}
                onCourtAdded={addNewCourt}
              />
            )}
          </TabPanel>
          <TabPanel>
            <LessonsList />
            {showLessonForm && (
              <LessonForm
                show={showLessonForm}
                onClose={toggleLessonForm}
              />
            )}
            <button onClick={toggleLessonForm} className="add-new-button">Add New Lesson</button>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default ClubProfile;
