import axiosInstance from './axiosInstance';

const handleUserAuthenticationResponse = async () => {
  try {
    const response = await axiosInstance.get('/users/callback/');
    return processAuthenticationResponse(response);
  } catch (error) {
    console.error('There was a problem with the user authentication callback:', error);
  }
};

const handleAdminAuthenticationResponse = async () => {
  try {
    const response = await axiosInstance.get('/users/admincallback/');
    return processAuthenticationResponse(response);
  } catch (error) {
    console.error('There was a problem with the admin authentication callback:', error);
  }
};

const processAuthenticationResponse = async (response) => {
  if (response.status !== 200) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = response.data;

  if (data.error) {
    console.error('Error during authentication:', data.description);
    return { success: false, message: data.description };
  } else if (data.message === 'Authentication successful.') {
    return { success: true, isAdmin: data.isAdmin, message: data.message };
  } else if (data.message === 'Account pending approval.') {
    return { success: false, message: data.message };
  } else {
    return { success: false, message: 'Unknown authentication response.' };
  }
};

const initiateLogout = async (isAdmin) => {
  const logoutUrl = isAdmin 
    ? '/users/admin-logout/' 
    : '/users/logout/';
  window.location.href = axiosInstance.defaults.baseURL + logoutUrl;
};

const initiateLogin = async (isAdmin) => {
  const loginUrl = isAdmin 
    ? '/users/admin-login/' 
    : '/users/login/';
  window.location.href = axiosInstance.defaults.baseURL + loginUrl;
};

export { handleUserAuthenticationResponse, handleAdminAuthenticationResponse, initiateLogout, initiateLogin };
