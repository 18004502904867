import React, { useEffect } from 'react';
import { initiateLogout } from '../api/userApi';

const ClubAdminLogout = () => {
  useEffect(() => {
    // Redirect to Azure AD B2C log out flow
    // window.location.href = 'http://tennetc-app-dev.azurewebsites.net/api/users/admin-logout/';
    const isAdmin = true;
    initiateLogout(isAdmin);
  }, []);

  return <div>Redirecting to log you out...</div>;
};

export default ClubAdminLogout;
