import React, { useState, useEffect } from 'react';
import { getCourts, deleteCourt } from '../api/ClubManagementApi/courtApi';
import EditCourtForm from './EditCourtForm'; // Import EditCourtForm
import './CourtsList.css';

const CourtsList = () => {
  const [courts, setCourts] = useState([]);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingCourt, setEditingCourt] = useState(null);

  const refreshCourts = async () => {
    // Function to refresh the courts list
    try {
      const response = await getCourts();
      setCourts(response || []);
    } catch (error) {
      console.error('Error refreshing courts:', error);
      setError('Failed to refresh courts.');
    }
  };

  useEffect(() => {
    fetchCourts();
  }, []);

  const fetchCourts = async () => {
    try {
      const response = await getCourts();
      setCourts(response || []);
    } catch (error) {
      console.error('Error fetching courts:', error);
      setError('Failed to fetch courts.');
    }
  };

  const handleEditCourt = (court) => {
    setEditingCourt(court);
    setIsEditing(true);
  };
  
  // Add this function
  const updateCourtList = (updatedCourt) => {
    setCourts(courts.map(court => court.id === updatedCourt.id ? updatedCourt : court));
  };

  const handleDeleteCourt = async (courtId) => {
    try {
      await deleteCourt(courtId);
      setCourts(courts.filter((court) => court.id !== courtId));
    } catch (error) {
      console.error('Error deleting court:', error);
      setError('Failed to delete court.');
    }
  };

  const closeEditModal = () => {
    setIsEditing(false);
    setEditingCourt(null);
    refreshCourts(); // Refresh the courts list
  };

  const displayOperatingHours = (hours) => {
    return Object.entries(hours).map(([day, times]) => (
      <div key={day}>
        {day}: Open {times.open}, Close {times.close}
      </div>
    ));
  };

  return (
    <div className="court-list-container">
      <h2>Your Courts</h2>
      {error && <p>{error}</p>}
      {courts.length > 0 ? (
        courts.map((court) => (
          <div key={court.id} className="court-item">
            <h3>{court.name}</h3>
            <div>{displayOperatingHours(court.operating_hours)}</div>
            <div className="court-item-buttons">
              <button onClick={() => handleEditCourt(court)} className="court-edit-button">Edit</button>
              {isEditing && (
                <EditCourtForm
                  show={isEditing}
                  onClose={closeEditModal}
                  courtDetails={editingCourt}
                  onUpdateCourtList={updateCourtList}
                  onRefreshCourts={refreshCourts}
                />
              )}
              <button onClick={() => handleDeleteCourt(court.id)} className="court-delete-button">Delete</button>
            </div>
          </div>
        ))
      ) : (
        <p>No courts found. Add a new court.</p>
      )}
    </div>
  );
};

export default CourtsList;
