import React, { useState } from 'react';
import './CourtResultItem.css';
import home_page_bckg from '../assets/home_page_bckg.jpg';
// import { initiateLogin } from '../api/userApi';
import { useNavigate } from 'react-router-dom';

const CourtResultItem = ({ clubData }) => {
  const [selectedSlot, setSelectedSlot] = useState(null);
  const navigate = useNavigate();

  const handleSelectSlot = (slot, courtId, date) => {
    setSelectedSlot(slot);
    const startTime = slot.time.split(' - ')[0];
    const bookingData = {
      type: 'court',
      court: courtId,
      booked_date: date, // Assumes 'date' is available in searchParams
      booked_start_time: startTime
    };
    sessionStorage.setItem('selectedBookingData', JSON.stringify(bookingData));
  };

  const handleBook = () => {
    if (!selectedSlot) {
      alert('Please select a slot before booking.');
      return;
    }

    const storedBookingData = sessionStorage.getItem('selectedBookingData');
    console.log(storedBookingData) 
    // window.location.href = 'http://tennetc-app-dev.azurewebsites.net/api/users/login/';
    // initiateLogin();
    navigate('/post-login');
  };

  return (
    <div className="court-result-item">
      <img src={clubData.courts[0].image || home_page_bckg} alt={clubData.club_name} className="court-image" />
      <div className="court-details">
        <h1>{clubData.club_name}</h1>
        {clubData.courts[0].distance_km != null && (
          <div className="distance-text">{clubData.courts[0].distance_km.toFixed(1)} km away from you</div>
        )}
        <h2>{clubData.club_address}</h2>
        {clubData.courts.map((court, index) => (
          <div key={index}>
            <h3>{court.court_name}</h3>
            {court.available_slots.map((slot, slotIndex) => (
              <div key={slotIndex} className="court-slot">
                <span className="court-time">{slot.time}</span>
                <span className="court-price">{Number(slot.price).toFixed(2)}</span>
                <input
                  type="radio"
                  name={`courtSlot-${index}`}
                  checked={selectedSlot === slot}
                  onChange={() => handleSelectSlot(slot, court.court_id, court.date)}
                />
              </div>
            ))}
          </div>
        ))}
        <button className="court-book-button" onClick={handleBook}>Book</button>
      </div>
    </div>
  );
};

export default CourtResultItem;
