import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [bookingData, setBookingData] = useState(null);

  const updateUser = (userData) => {
    setUser(userData);
    console.log(user)
  };

  const updateBookingData = (data) => {
    setBookingData(data);
  };

  return (
    <UserContext.Provider value={{ user, updateUser, bookingData, updateBookingData }}>
      {children}
    </UserContext.Provider>
  );
};
