import React, { createContext, useState } from 'react';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState(null);
  const [searchParams, setSearchParams] = useState({});

  // Function to update search parameters
  const updateSearchParams = (newParams) => {
    setSearchParams(prevParams => ({ ...prevParams, ...newParams }));
  };

  // Function to clear search results
  const clearSearchResults = () => {
    setSearchResults(null);
  };

  const value = {
    searchResults,
    setSearchResults,
    searchParams,
    updateSearchParams,
    clearSearchResults
  };

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
};
