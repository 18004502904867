import axiosInstance from '../axiosInstance';

// create a court price
export const createCourtPricing = async (pricingData) => {
  try {
    const response = await axiosInstance.post('/club-management/court-pricing/', pricingData);
    return response.data;
  } catch (error) {
    console.error('Error creating court pricing:', error);
  }
};

// list court prices
export const getCourtPricing = async () => {
    try {
      const response = await axiosInstance.get('/club-management/court-pricing/');
      return response.data;
    } catch (error) {
      console.error('Error fetching court pricing:', error);
    }
  };

// update a court price
export const updateCourtPricing = async (pricingId, updatedData) => {
    try {
      const response = await axiosInstance.put(`/club-management/court-pricing/${pricingId}/`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating court pricing:', error);
    }
  };

// delete a court price
export const deleteCourtPricing = async (pricingId) => {
    try {
      const response = await axiosInstance.delete(`/club-management/court-pricing/${pricingId}/`);
      return response.status;
    } catch (error) {
      console.error('Error deleting court pricing:', error);
    }
  };

// patch a court price
export const patchCourtPricing = async (pricingId, patchData) => {
    try {
      const response = await axiosInstance.patch(`/club-management/court-pricing/${pricingId}/`, patchData);
      return response.data;
    } catch (error) {
      console.error('Error patching court pricing:', error);
    }
  };

