import axiosInstance from '../axiosInstance';

// create a lesson schedule
export const createLessonSchedule = async (scheduleData) => {
  try {
    const response = await axiosInstance.post('/club-management/lesson-schedules/', scheduleData);
    return response.data;
  } catch (error) {
    console.error('Error creating lesson schedule:', error);
  }
};

// list lesson schedules
export const getLessonSchedule = async () => {
    try {
      const response = await axiosInstance.get('/club-management/lesson-schedules/');
      return response.data;
    } catch (error) {
      console.error('Error fetching lesson schedule:', error);
    }
  };

// update a lesson schedule
export const updateLessonSchedule = async (scheduleId, updatedData) => {
    try {
      const response = await axiosInstance.put(`/club-management/lesson-schedules/${scheduleId}/`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating lesson schedule:', error);
    }
  };

// delete a lesson schedule
export const deleteLessonSchedule = async (scheduleId) => {
    try {
      const response = await axiosInstance.delete(`/club-management/lesson-schedules/${scheduleId}/`);
      return response.status;
    } catch (error) {
      console.error('Error deleting lesson schedule:', error);
    }
  };

// patch a lesson schedule
export const patchLessonSchedule = async (scheduleId, updatedData) => {
    try {
      const response = await axiosInstance.patch(`/club-management/lesson-schedules/${scheduleId}/`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating lesson schedule:', error);
    }
  };