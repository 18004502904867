import React, { useEffect } from 'react';
import { initiateLogin } from '../api/userApi';

const ClubAdminLogin = () => {
  useEffect(() => {
    // Redirect to Azure AD B2C login flow
    // window.location.href = 'http://tennetc-app-dev.azurewebsites.net/api/users/admin-login/';
    const isAdmin = true;
    initiateLogin(isAdmin);
  }, []);

  return <div>Redirecting to login...</div>;
};

export default ClubAdminLogin;
