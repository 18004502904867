import React, { useState, useEffect } from 'react';
import { updateLesson } from '../api/ClubManagementApi/lessonApi';
import { getLessonSchedule, updateLessonSchedule } from '../api/ClubManagementApi/lessonScheduleApi';
import { getCourts } from '../api/ClubManagementApi/courtApi';
// import './EditLessonForm.css';

const EditLessonForm = ({ show, onClose, lessonDetails }) => {
  const [lesson, setLesson] = useState(lessonDetails || {});
  const [lessonSchedule, setLessonSchedule] = useState([]);
  const [courts, setCourts] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCourts = async () => {
      try {
        const response = await getCourts();
        setCourts(response.results || []);
      } catch (error) {
        setError('Failed to fetch courts.');
      }
    };

    fetchCourts();

    if (lessonDetails) {
        const fetchLessonSchedules = async () => {
          try {
            const schedulesResponse = await getLessonSchedule(); // Fetch all schedules
            const lessonSchedules = schedulesResponse.results || [];
    
            // Find the schedule for the current lesson
            const currentLessonSchedule = lessonSchedules.find(
              (schedule) => schedule.lesson === lessonDetails.id
            );
    
            // Transform the schedule data
            if (currentLessonSchedule && currentLessonSchedule.schedule) {
              const transformedSchedule = Object.keys(currentLessonSchedule.schedule).map(day => ({
                id: currentLessonSchedule.id,
                day,
                start_time: currentLessonSchedule.schedule[day].start_time,
                end_time: currentLessonSchedule.schedule[day].end_time,
              }));
    
              setLessonSchedule(transformedSchedule);
            } else {
              setLessonSchedule([]);
            }
          } catch (error) {
            setError('Failed to fetch lesson schedules.');
          }
        };
    
        fetchLessonSchedules();
      }
    }, [lessonDetails]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLesson({ ...lesson, [name]: value });
  };

  const handleScheduleChange = (index, event) => {
    const updatedSchedule = [...lessonSchedule];
    updatedSchedule[index][event.target.name] = event.target.value;
    setLessonSchedule(updatedSchedule);
  };

  const addScheduleEntry = () => {
    setLessonSchedule([...lessonSchedule, { day: '', start_time: '', end_time: '' }]);
  };

  const removeScheduleEntry = (index) => {
    const updatedSchedule = lessonSchedule.filter((_, i) => i !== index);
    setLessonSchedule(updatedSchedule);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
  
    try {
      // Update lesson details
      await updateLesson(lesson.id, lesson);
  
      // Update lesson schedules
      for (const scheduleEntry of lessonSchedule) {
        // Construct the schedule data in the format expected by the backend
        const scheduleData = {
          lesson: lesson.id, // Ensure the lesson ID is included
          ...scheduleEntry,
          schedule: {
            [scheduleEntry.day]: {
              start_time: scheduleEntry.start_time,
              end_time: scheduleEntry.end_time,
            },
          },
        };
  
        // Check if the schedule entry has an ID (existing schedule)
        if (scheduleEntry.id) {
          await updateLessonSchedule(scheduleEntry.id, scheduleData);
        } else {
          // Logic for creating new schedule entries if necessary
          // Add here the logic to create a new schedule entry
          // For example: await createLessonSchedule(scheduleData);
        }
      }
  
      onClose(); // Refresh or update the lessons list in parent component
    } catch (error) {
      setError('An error occurred while updating the lesson.');
    }
  };  

  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <form onSubmit={handleSubmit} className="lesson-form">
          <h1>Edit Lesson</h1>
          
          {/* Lesson Name */}
          <div className="form-group">
            <label htmlFor="name">Lesson Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={lesson.name}
              onChange={handleInputChange}
              required
            />
          </div>
  
          {/* Lesson Type */}
          <div className="form-group">
            <label htmlFor="type">Type:</label>
            <select
              id="type"
              name="type"
              value={lesson.type}
              onChange={handleInputChange}
              required
            >
              <option value="group_adult">Group Adult</option>
              <option value="group_junior">Group Junior</option>
            </select>
          </div>
  
          {/* Lesson Price */}
          <div className="form-group">
            <label htmlFor="price">Price:</label>
            <input
              type="number"
              id="price"
              name="price"
              value={lesson.price}
              onChange={handleInputChange}
              required
            />
          </div>
  
          {/* Lesson Capacity */}
          <div className="form-group">
            <label htmlFor="capacity">Capacity:</label>
            <input
              type="number"
              id="capacity"
              name="capacity"
              value={lesson.capacity}
              onChange={handleInputChange}
              required
            />
          </div>
  
          {/* Lesson Level */}
          <div className="form-group">
            <label htmlFor="level">Level:</label>
            <select
              id="level"
              name="level"
              value={lesson.level}
              onChange={handleInputChange}
              required
            >
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
          </div>
  
          {/* Lesson Description */}
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={lesson.description}
              onChange={handleInputChange}
              required
            />
          </div>
  
          {/* Lesson Schedule Entries */}
          {lessonSchedule.map((entry, index) => (
            <div key={index} className="schedule-entry">
              <div className="form-group">
                <label htmlFor={`day-${index}`}>Day:</label>
                <select
                  id={`day-${index}`}
                  name="day"
                  value={entry.day}
                  onChange={(e) => handleScheduleChange(index, e)}
                  required
                >
                  {/* Day options */}
                  <option value="">Select Day</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>
              </div>
              
              {/* Start Time */}
              <div className="form-group">
                <label htmlFor={`start-time-${index}`}>Start Time:</label>
                <input
                  type="time"
                  id={`start-time-${index}`}
                  name="start_time"
                  value={entry.start_time}
                  onChange={(e) => handleScheduleChange(index, e)}
                  required
                />
              </div>
  
              {/* End Time */}
              <div className="form-group">
                <label htmlFor={`end-time-${index}`}>End Time:</label>
                <input
                  type="time"
                  id={`end-time-${index}`}
                  name="end_time"
                  value={entry.end_time}
                  onChange={(e) => handleScheduleChange(index, e)}
                  required
                />
              </div>
  
              {/* Remove Schedule Button */}
              {lessonSchedule.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeScheduleEntry(index)}
                  className="remove-schedule-button"
                >
                  Remove Schedule
                </button>
              )}
            </div>
          ))}
  
          {/* Add Schedule Entry Button */}
          <button
            type="button"
            onClick={addScheduleEntry}
            className="add-schedule-button"
          >
            Add Schedule Entry
          </button>
  
          {/* Submit and Close Buttons */}
          <button type="submit" className="submit-button">Update Lesson</button>
          {error && <p className="error-message">{error}</p>}
          <button type="button" className="close-button" onClick={onClose}>Close</button>
        </form>
      </div>
    </div>
  );  
};

export default EditLessonForm;
