import React, { useState, useEffect } from 'react';
import { createLesson } from '../api/ClubManagementApi/lessonApi';
import { getCourts } from '../api/ClubManagementApi/courtApi';
import { createLessonSchedule } from '../api/ClubManagementApi/lessonScheduleApi';
import './LessonForm.css';

const initialLessonState = {
  court: '',
  name: '',
  type: 'group_adult',
  price: '',
  capacity: '',
  level: 'beginner',
  description: ''
};

const initialScheduleState = {
  day: '',
  start_time: '',
  end_time: ''
};

const LessonForm = ({ show, onClose }) => {
  const [lessonDetails, setLessonDetails] = useState(initialLessonState);
  const [lessonSchedule, setLessonSchedule] = useState([initialScheduleState]);
  const [startDate, setStartDate] = useState('');
  const [numberOfWeeks, setNumberOfWeeks] = useState('');
  const [courts, setCourts] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCourtOperatingHours, setSelectedCourtOperatingHours] = useState({});

  useEffect(() => {
    // Fetch courts when the component mounts
    const fetchCourts = async () => {
      try {
        const response = await getCourts();
        setCourts(response || []);
      } catch (error) {
        setError('Failed to fetch courts.');
      }
    };

    fetchCourts();
  }, []);

  useEffect(() => {
    if (lessonDetails.court) {
      const selectedCourt = courts.find(court => court.id.toString() === lessonDetails.court);
      setSelectedCourtOperatingHours(selectedCourt ? selectedCourt.operating_hours : {});
    }
  }, [lessonDetails.court, courts]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'price' || name === 'capacity') {
      // Cast to a number and ensure it's not negative
      const numValue = Math.max(0, parseFloat(value));
      setLessonDetails(prevDetails => ({ ...prevDetails, [name]: numValue }));
    } else {
      setLessonDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    }
    if (name === 'court') {
      const selectedCourt = courts.find(court => court.id.toString() === value);
      setSelectedCourtOperatingHours(selectedCourt ? selectedCourt.operating_hours : {});

      if (selectedCourt && selectedCourt.operating_hours) {
        const firstDay = Object.keys(selectedCourt.operating_hours)[0];
        setLessonSchedule([{
          day: firstDay,
          start_time: selectedCourt.operating_hours[firstDay].open,
          end_time: selectedCourt.operating_hours[firstDay].close,
        }]);
      } else {
        setLessonSchedule([{ ...initialScheduleState }]);
      }
    }
  };

  const handleScheduleInputChange = (index, event) => {
    const updatedSchedule = [...lessonSchedule];
    const { name, value } = event.target;

    if (name === 'day' && selectedCourtOperatingHours[value]) {
      updatedSchedule[index] = {
        ...updatedSchedule[index],
        day: value,
        start_time: selectedCourtOperatingHours[value].open,
        end_time: selectedCourtOperatingHours[value].close
      };
    } else {
      updatedSchedule[index] = { ...updatedSchedule[index], [name]: value };
    }

    setLessonSchedule(updatedSchedule);
  };

  const addScheduleEntry = () => {
    if (selectedCourtOperatingHours && Object.keys(selectedCourtOperatingHours).length > 0) {
      const firstAvailableDay = Object.keys(selectedCourtOperatingHours)[0];
      const newEntry = {
        day: firstAvailableDay,
        start_time: selectedCourtOperatingHours[firstAvailableDay].open,
        end_time: selectedCourtOperatingHours[firstAvailableDay].close
      };
      setLessonSchedule([...lessonSchedule, newEntry]);
    } else {
      setLessonSchedule([...lessonSchedule, { ...initialScheduleState }]);
    }
  };

  const removeScheduleEntry = (index) => {
    const newScheduleEntries = lessonSchedule.filter((_, i) => i !== index);
    setLessonSchedule(newScheduleEntries);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // Create lesson
      const lessonResponse = await createLesson(lessonDetails);
      
      // Prepare and create lesson schedule
      const schedule = lessonSchedule.reduce((acc, curr) => {
        if (curr.day && curr.start_time && curr.end_time) {
          acc[curr.day] = { start_time: curr.start_time, end_time: curr.end_time };
        }
        return acc;
      }, {});

      await createLessonSchedule({
        lesson: lessonResponse.id,
        schedule,
        start_date: startDate,
        number_of_weeks: numberOfWeeks
      });
      
      setIsLoading(false);
      onClose(); // Success handling: clear form, show success message, etc.
    } catch (error) {
      // Specific error handling based on the error response
      setError('An error occurred while creating the lesson and schedule.');
      setIsLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <>
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className={`modal-backdrop ${isLoading ? 'modal-blur' : ''}`}>
        <div className="modal-backdrop">
          <div className="modal">
            <form onSubmit={handleSubmit} className="lesson-form">
              <h1>Add New Group Lesson</h1>
              {/* Court dropdown */}
              <div className="form-group">
                <label htmlFor="court">Court:</label>
                <select
                  id="court"
                  name="court"
                  value={lessonDetails.court}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Court</option>
                  {courts.map((court) => (
                    <option key={court.id} value={court.id}>
                      {court.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={lessonDetails.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="type">Type:</label>
                <select
                  id="type"
                  name="type"
                  value={lessonDetails.type}
                  onChange={handleInputChange}
                  required
                >
                  <option value="group_adult">Group Adult</option>
                  <option value="group_junior">Group Junior</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="price">Price:</label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={lessonDetails.price}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="capacity">Capacity:</label>
                <input
                  type="number"
                  id="capacity"
                  name="capacity"
                  value={lessonDetails.capacity}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="level">Level:</label>
                <select
                  id="level"
                  name="level"
                  value={lessonDetails.level}
                  onChange={handleInputChange}
                  required
                >
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={lessonDetails.description}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {lessonSchedule.map((entry, index) => (
                <div key={index} className="schedule-entry">
                  {/* Dropdown for selecting the day */}
                  <label>
                    Day of the Week:
                    <select
                      name="day"
                      value={entry.day}
                      onChange={(e) => handleScheduleInputChange(index, e)}
                      required
                    >
                      <option value="">Select Day</option>
                      {Object.keys(selectedCourtOperatingHours).map(day => (
                        <option key={day} value={day}>{day}</option>
                      ))}
                    </select>
                  </label>
                  {/* Input fields for start and end times */}
                  <label>
                    Start Time:
                    <input
                      type="time"
                      name="start_time"
                      value={entry.start_time}
                      onChange={(e) => handleScheduleInputChange(index, e)}
                      required
                    />
                  </label>
                  <label>
                    End Time:
                    <input
                      type="time"
                      name="end_time"
                      value={entry.end_time}
                      onChange={(e) => handleScheduleInputChange(index, e)}
                      required
                    />
                  </label>
                  {lessonSchedule.length > 1 && (
                    <button type="button" onClick={() => removeScheduleEntry(index)}>
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button type="button" onClick={addScheduleEntry}>
                  Add Schedule Entry
              </button>

              {/* Start date and number of weeks inputs */}
              <div className="form-group">
                  <label htmlFor="start_date">Start Date:</label>
                  <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                  required
                  />
              </div>
              <div className="form-group">
                  <label htmlFor="number_of_weeks">Number of Weeks:</label>
                  <input
                  type="number"
                  id="number_of_weeks"
                  name="number_of_weeks"
                  value={numberOfWeeks}
                  onChange={e => setNumberOfWeeks(e.target.value)}
                  required
                  />
              </div>

              {/* Submit button */}
              <button type="submit" className="submit-button">Submit</button>
              {error && <p className="error-message">{error}</p>}
              <button className="close-button" onClick={onClose}>Close</button>
              </form>
            </div>
        </div>
    </div>
    </>
  );
};

export default LessonForm;
