import axiosInstance from '../axiosInstance';

// list clubs
export const getClubs = async () => {
  try {
    const response = await axiosInstance.get('/club-management/clubs/');
    return response.data;
  } catch (error) {
    console.error('Error fetching clubs:', error);
  }
};

// create a club
export const createClub = async (clubData) => {
    try {
      const response = await axiosInstance.post('/club-management/clubs/', clubData);
      return response.data;
    } catch (error) {
      console.error('Error creating club:', error);
    }
  };

// update a club
export const updateClub = async (clubId, updatedData) => {
    try {
      const response = await axiosInstance.put(`/club-management/clubs/${clubId}/`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating club:', error);
    }
  };

// delete a club
export const deleteClub = async (clubId) => {
    try {
      const response = await axiosInstance.delete(`/club-management/clubs/${clubId}/`);
      return response.status;
    } catch (error) {
      console.error('Error deleting club:', error);
    }
  };
  
// patch a club
export const patchClub = async (clubId, patchData) => {
    try {
      const response = await axiosInstance.patch(`/club-management/clubs/${clubId}/`, patchData);
      return response.data;
    } catch (error) {
      console.error('Error patching club:', error);
    }
  };
  