import React, { useState, useEffect } from 'react';
import { updateCourt } from '../api/ClubManagementApi/courtApi';
import { updateCourtPricing, getCourtPricing, createCourtPricing, deleteCourtPricing } from '../api/ClubManagementApi/courtPricingApi';
// import './EditCourtForm.css';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const EditCourtForm = ({ show, onClose, courtDetails }) => {
    const [courtName, setCourtName] = useState(courtDetails.name);
    const [status, setStatus] = useState(courtDetails.status);
    const [selectedDays, setSelectedDays] = useState(new Set(Object.keys(courtDetails.operating_hours)));
    const [operatingHours, setOperatingHours] = useState(courtDetails.operating_hours);
    const [pricingWindows, setPricingWindows] = useState({});
    const [error, setError] = useState('');
  
    useEffect(() => {
      if (courtDetails) {
        setCourtName(courtDetails.name);
        setStatus(courtDetails.status);
        // setSelectedDays(new Set(Object.keys(courtDetails.operating_hours)));
        // setOperatingHours(courtDetails.operating_hours);
    
        const fetchPricingWindows = async () => {
          try {
            const response = await getCourtPricing();
            if (response && Array.isArray(response.results)) {
              const filteredPricing = response.results
                .filter(pricing => pricing.court === courtDetails.id)
                .map(pricing => ({...pricing, day_of_week: pricing.day_of_week}));
    
              // Format for easier display
              const formattedPricing = daysOfWeek.reduce((acc, day) => {
                acc[day] = filteredPricing.filter(pricing => pricing.day_of_week === day);
                return acc;
              }, {});
              
              setPricingWindows(formattedPricing);
            } else {
              console.error("Unexpected data format for pricing windows:", response);
              setPricingWindows({});
            }
          } catch (error) {
            console.error("Error fetching pricing windows: ", error);
            setError("Failed to fetch pricing windows.");
            setPricingWindows({});
          }
        };
    
        fetchPricingWindows();
      }
    }, [courtDetails]);
    
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'courtName') {
      setCourtName(value);
    } else if (name === 'status') {
      setStatus(value);
    }
  };

  const handleDaySelection = (day) => {
    setSelectedDays((prevSelectedDays) => {
      const newSelectedDays = new Set(prevSelectedDays);
      newSelectedDays.has(day) ? newSelectedDays.delete(day) : newSelectedDays.add(day);
      return newSelectedDays;
    });
  };

  const handleOperatingHoursChange = (day, openOrClose, value) => {
    setOperatingHours((prevHours) => ({
      ...prevHours,
      [day]: { ...prevHours[day], [openOrClose]: value }
    }));
  };

  const handlePricingChange = (day, index, event) => {
    setPricingWindows(prevPricing => {
        const updatedDayPricing = (prevPricing[day] || []).map((pricing, idx) => {
            if (idx === index) {
                return { ...pricing, [event.target.name]: event.target.value };
            }
            return pricing;
        });

        return { ...prevPricing, [day]: updatedDayPricing };
    });
  };

  const addPricingWindow = (day) => {
    const newWindow = { day_of_week: day, time_window_start: '', time_window_end: '', price: '' };
    setPricingWindows(prevPricing => ({
        ...prevPricing,
        [day]: [...(prevPricing[day] || []), newWindow],
    }));
  };
  
  const removePricingWindow = (day, index) => {
    setPricingWindows(prevPricing => ({
        ...prevPricing,
        [day]: prevPricing[day].filter((_, idx) => idx !== index),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
  
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
  };

    // Helper function to convert minutes since midnight to time string
    const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
        const mins = (minutes % 60).toString().padStart(2, '0');
        return `${hours}:${mins}`;
    };

    const calculatedOperatingHours = Object.keys(pricingWindows).reduce((acc, day) => {
        const validWindows = pricingWindows[day].filter(window => window.time_window_start && window.time_window_end);
        if (validWindows.length > 0) {
            const openTimes = validWindows.map(window => timeToMinutes(window.time_window_start));
            const closeTimes = validWindows.map(window => timeToMinutes(window.time_window_end));
            acc[day] = {
                open: minutesToTime(Math.min(...openTimes)),
                close: minutesToTime(Math.max(...closeTimes))
            };
        }
        return acc;
    }, {});

    console.log(calculatedOperatingHours)

    try {
      await updateCourt(courtDetails.id, { name: courtName, operating_hours: calculatedOperatingHours, status });
  
        // Flatten the pricing windows for easier processing
        const updatedPricingWindows = Object.values(pricingWindows).flat();
  
        // Update existing and create new pricing windows
        for (const pricing of updatedPricingWindows) {
            if (pricing.id) {
                console.log("Updating pricing window:", pricing.day_of_week);
                // Update existing pricing window
                await updateCourtPricing(pricing.id, { ...pricing, court: courtDetails.id });
            } else {
                // Create new pricing window
                await createCourtPricing({ ...pricing, court: courtDetails.id });
            }
        }
  
        // Handle removed pricing windows
        const existingPricingIds = (courtDetails.pricingWindows || []).map(p => p.id);
        const updatedPricingIds = updatedPricingWindows.map(p => p.id || null);
  
        const removedPricingIds = existingPricingIds.filter(id => !updatedPricingIds.includes(id));
        for (const id of removedPricingIds) {
            await deleteCourtPricing(id);
        }
  
        onClose(); // Close the modal
    } catch (error) {
        setError('An error occurred while updating the court.');
    }
  };
  

  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <form onSubmit={handleSubmit} className="court-form">
            <h1>Edit Court</h1>

            {/* Court Name */}
            <div className="form-group">
                <label>Court Name:</label>
                <input
                type="text"
                name="courtName"
                value={courtName}
                onChange={handleInputChange}
                required
                />
            </div>

            {/* Status */}
            <div className="form-group">
                <label>Status:</label>
                <select
                name="status"
                value={status}
                onChange={handleInputChange}
                required
                >
                <option value="available">Available</option>
                <option value="maintenance">Under Maintenance</option>
                <option value="closed">Closed</option>
                </select>
            </div>

            <div className="form-group">
                <label>Operating Days:</label>
                {daysOfWeek.map((day) => (
                <div key={day} className="checkbox-grid">
                    <label>{day}</label>
                    <input
                    type="checkbox"
                    checked={selectedDays.has(day)}
                    onChange={() => handleDaySelection(day)}
                    />
                </div>
                ))}
            </div>

            {/* Operating Hours and Pricing for Each Day */}
            {Array.from(selectedDays).sort().map((day) => (
                <div key={day} className="day-section">
                  {/* <h3>{day} Operating Hours</h3>
                  <div className="day-hours">
                      <div className="form-group">
                      <label>Open Time:</label>
                      <input
                          type="time"
                          value={operatingHours[day]?.open || ''}
                          onChange={(e) => handleOperatingHoursChange(day, 'open', e.target.value)}
                          required
                      />
                      </div>
                      <div className="form-group">
                      <label>Close Time:</label>
                      <input
                          type="time"
                          value={operatingHours[day]?.close || ''}
                          onChange={(e) => handleOperatingHoursChange(day, 'close', e.target.value)}
                          required
                      />
                      </div>
                  </div> */}

                {/* Pricing Section for the Day */}
                <div className="day-pricing-section">
                    <h3>{day} Pricing</h3>
                    {(pricingWindows[day] || []).map((pricing, index) => {
                        return (
                            <div className="pricing-window" key={`${day}-${index}`}>
                                {/* Pricing Time Inputs */}
                                <div className="form-group">
                                <label>Start Time:</label>
                                <input
                                    type="time"
                                    name="time_window_start"
                                    value={pricing.time_window_start}
                                    min={operatingHours[day]?.open || ''}
                                    max={operatingHours[day]?.close || ''}
                                    onChange={(e) => handlePricingChange(day, index, e)}
                                    required
                                />
                                </div>
                                <div className="form-group">
                                <label>End Time:</label>
                                <input
                                    type="time"
                                    name="time_window_end"
                                    value={pricing.time_window_end}
                                    min={operatingHours[day]?.open || ''}
                                    max={operatingHours[day]?.close || ''}
                                    onChange={(e) => handlePricingChange(day, index, e)}
                                    required
                                />
                                </div>
                                {/* Pricing Fee Input */}
                                <div className="form-group">
                                <label>Fee:</label>
                                <input
                                    type="number"
                                    name="price"
                                    value={pricing.price}
                                    onChange={(e) => handlePricingChange(day, index, e)}
                                    required
                                />
                                </div>
                                <button
                                type="button"
                                className="remove-window-button"
                                onClick={() => removePricingWindow(day, index)}
                                >
                                Remove
                                </button>
                            </div>
                        );
                    })}
                    <button
                    type="button"
                    className="add-window-button"
                    onClick={() => addPricingWindow(day)}
                    >
                    Add Pricing Window for {day}
                    </button>
                </div>
                </div>
            ))}

            {/* Submit and Close Buttons */}
            <button type="submit" className="submit-button">Update Court</button>
            {error && <p className="error-message">{error}</p>}
            <button className="close-button" onClick={onClose}>Close</button>
        </form>
      </div>
    </div>
  );
};

export default EditCourtForm;
