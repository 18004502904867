import React from 'react';
import './LessonResultItem.css'; // Ensure you have the correct path to your CSS file
import home_page_bckg from '../assets/home_page_bckg.jpg'; // Adjust the path to where your image is located
// import { initiateLogin } from '../api/userApi';
import { useNavigate } from 'react-router-dom';

const LessonResultItem = ({ lesson }) => {
  const navigate = useNavigate();

  const handleBook = () => {
    // Prepare the booking data
    const bookingData = {
      lesson: lesson.id,
      type: 'lesson', // Assuming 'lesson' is the type you want to store
    };

    // Store the booking data in sessionStorage
    sessionStorage.setItem('selectedBookingData', JSON.stringify(bookingData));

    // Redirect to the Azure AD B2C login flow or booking logic
    // window.location.href = 'http://tennetc-app-dev.azurewebsites.net/api/users/login/';
    // initiateLogin();
    navigate('/post-login');
  };

  // Function to format the schedule into a list of strings
  const formatSchedule = (schedule) => {
    const days = Object.keys(schedule);
    return days.map((day) => `${day}: ${schedule[day].start_time} - ${schedule[day].end_time}`);
  };

  return (
    <div className="lesson-result-item">
      <img src={home_page_bckg} alt={lesson.name} className="lesson-image" />
      <div className="lesson-details">
        <h2>{lesson.club_name}</h2>
        {lesson.distance != null && (
          <div className="distance-text">{lesson.distance.toFixed(1)} km away from you</div>
        )}
        <h3>{lesson.name}</h3>
        <span className="subheading">Start date:</span>
        <p>{lesson.start_date}</p>
        <span className="subheading">Description:</span>
        <p>{lesson.description}</p>
        <span className="subheading">Capacity:</span>
        <p>{lesson.capacity}</p>
        <span className="subheading">Number of sessions:</span>
        <p>{lesson.number_of_sessions}</p>
        <span className="subheading">Price:</span>
        <p>${lesson.price} CAD</p>
        <span className="subheading">Proficiency level:</span>
        <p>{lesson.level}</p>
        {/* Display the formatted schedule */}
        <div className="lesson-schedule">
          <strong>Schedule:</strong>
          <ul>
            {formatSchedule(lesson.schedule).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <button className="lesson-book-button" onClick={handleBook}>Book</button>
      </div>
    </div>
  );
};

export default LessonResultItem;
