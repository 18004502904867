import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PaymentSuccess.css'; 

const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className= "container">
      <h1 className= "title">Payment Successful!</h1>
      <p className= "message">Your payment has been processed successfully.</p>
      <button className= "button" onClick={() => navigate('/')}>Go Home</button>
    </div>
  );
};

export default PaymentSuccess;
