import React, { useContext } from 'react';
import { SearchContext } from '../context/SearchContext';
import LessonResultItem from './LessonResultItem';
import './LessonResults.css'; // Ensure you have the correct path to your CSS file

const LessonResults = () => {
  const { searchResults } = useContext(SearchContext);

  if (!searchResults) {
    return <div className="loading">SEARCHING...</div>;
  }

  const lessons = searchResults;

  return (
    <div className="lesson-results-container">
      {lessons.length > 0 ? (
        lessons.map((lesson) => (
          <LessonResultItem key={lesson.id} lesson={lesson} />
        ))
      ) : (
        <p className="no-results-message">No lessons found for the selected filters.</p>
      )}
    </div>
  );
};

export default LessonResults;