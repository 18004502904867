import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthSuccessAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the token from the URL fragment.
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    const token = hashParams.get('token');
  
    if (token) {
      // Store the token in session storage.
      sessionStorage.setItem('authToken', token);
      // Navigate to the club profile page.
      navigate('/club-profile');
    } else {
      // If no token is found, navigate back to the homepage.
      navigate('/');
    }
  }, [navigate]);

  return <div>Authenticating...</div>;
};

export default AuthSuccessAdmin;
