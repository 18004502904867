import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Using slice instead of substr
    const hashParams = window.location.hash.slice(1);
    const token = new URLSearchParams(hashParams).get('token');

    if (token) {
      sessionStorage.setItem('authToken', token);

      // navigate('/post-login');
      navigate('/home');
    } else {
      navigate('/');
    }
  }, [navigate]);

  return <div>Authenticating...</div>;
};

export default AuthSuccess;
