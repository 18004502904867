import axiosInstance from '../axiosInstance';

// get a court calendar
export const getCourtCalendar = async (courtId) => {
  try {
    const response = await axiosInstance.get(`/court-calendar/court/${courtId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching court calendar:', error);
  }
};
