import React, { useState, useEffect } from 'react';
import { getLessons, deleteLesson } from '../api/ClubManagementApi/lessonApi';
import EditLessonForm from './EditLessonForm'; // Import the EditLessonForm
import './LessonsList.css';

const LessonsList = () => {
  const [lessons, setLessons] = useState([]);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingLesson, setEditingLesson] = useState(null);

  useEffect(() => {
    fetchLessons();
  }, []);

  const fetchLessons = async () => {
    try {
      const response = await getLessons();
      setLessons(response || []);
    } catch (error) {
      console.error('Error fetching lessons:', error);
      setError('Failed to fetch lessons.');
    }
  };

  const handleEditLesson = (lesson) => {
    setEditingLesson(lesson);
    setIsEditing(true);
  };

  const handleDeleteLesson = async (lessonId) => {
    try {
      await deleteLesson(lessonId);
      setLessons(lessons.filter((lesson) => lesson.id !== lessonId));
    } catch (error) {
      console.error('Error deleting lesson:', error);
      setError('Failed to delete lesson.');
    }
  };

  const closeEditModal = () => {
    setIsEditing(false);
    setEditingLesson(null);
    fetchLessons(); // Refresh lessons list after editing
  };

  return (
    <div className="lesson-list-container">
      <h2>Your Lessons</h2>
      {error && <p>{error}</p>}
      {lessons.length > 0 ? (
        lessons.map((lesson) => (
          <div key={lesson.id} className="lesson-item">
            <h2>{lesson.name}</h2>
            <div className="lesson-item-buttons">
              <button onClick={() => handleEditLesson(lesson)} className="lesson-edit-button">Edit</button>
              {isEditing && (
              <EditLessonForm
                show={isEditing}
                onClose={closeEditModal}
                lessonDetails={editingLesson}
              />
              )}
              <button onClick={() => handleDeleteLesson(lesson.id)} className="lesson-delete-button">Delete</button>
            </div>
          </div>
        ))
      ) : (
        <p>No lessons found. Add a new lesson.</p>
      )}
    </div>
  );
};

export default LessonsList;
