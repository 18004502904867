import React, { useState } from 'react';
import './FilterModal.css'; // Import the corresponding CSS file

const FilterModal = ({ show, activeTab, onClose, onApply }) => {
    const [distance, setDistance] = useState('');
    const [max_price, setPrice] = useState('');
    const [proficiency_level, setProficiencyLevel] = useState('');
  
    if (!show) {
      return null;
    }
  
    return (
      <div className="filter-modal">
        <div className="filter-content">
          <label>
            Distance (km):
            <input 
              type="number"
              name = "distance" 
              value={distance} 
              onChange={(e) => setDistance(e.target.value)} 
              placeholder="Enter max distance" 
            />
          </label>
          <label>
            Price (CAD):
            <input 
              type="number"
              name = "max_price"  
              value={max_price} 
              onChange={(e) => setPrice(e.target.value)} 
              placeholder="Enter max price" 
            />
          </label>
          {activeTab === 'lesson' && (
            <label>
              Proficiency Level:
              <select 
                name="proficiency_level"
                value={proficiency_level} 
                onChange={(e) => setProficiencyLevel(e.target.value)}
              >
                <option value="">Select level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </label>
          )}
          <button onClick={() => onApply({ distance, max_price, proficiency_level })}>Apply</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  export default FilterModal;
  