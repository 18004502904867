import React, { useState, useContext } from 'react';
import './SearchArea.css';
import { FaFilter, FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { SearchContext } from '../context/SearchContext';
import { searchCourts, searchLessons } from '../api/BookingApi/bookingApi'; // Adjust this import path as needed
import FilterModal from './FilterModal';

const SearchArea = () => {
  const [inputType, setInputType] = useState('city');
  const navigate = useNavigate(); // Correctly defined within the component scope
  const { setSearchResults } = useContext(SearchContext);
  const [activeTab, setActiveTab] = useState('court');
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams, setSearchParams] = useState({
    cityZip: '',
    date: '',
    time_range: '',
    distance: '',
    max_price: '',
    lesson_type: '',
    proficiency_level: '',
    start_date: '',
  });
  const [loading, setLoading] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  
    if (name === 'cityZip') {
      if (/\d/.test(value)) {
        setInputType('postal_code');
      } else {
        setInputType('city');
      }
    }
  };
  
  const handleApplyFilter = (filters) => {
    // Assuming 'filters' is an object with keys matching those in 'searchParams'
    setSearchParams({ ...searchParams, ...filters });
    setShowFilters(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    let queryParams = {};
  
    if (inputType === 'city' && searchParams.cityZip) {
      queryParams.city = searchParams.cityZip;
    } else if (inputType === 'postal_code' && searchParams.cityZip) {
      queryParams.postal_code = searchParams.cityZip;
    }
  
    if (searchParams.date) {
      queryParams.date = searchParams.date; // Ensure this is in YYYY-MM-DD format
    }
  
    if (searchParams.time_range) {
      queryParams.time_range = searchParams.time_range;
    }
  
    if (searchParams.max_price) {
      queryParams.max_price = parseFloat(searchParams.max_price).toFixed(2);
    }
  
    if (searchParams.distance) {
      queryParams.distance = parseInt(searchParams.distance, 10);
    }
    
    // For 'lesson' tab, include additional parameters
    if (activeTab === 'lesson') {
      if (searchParams.lesson_type) {
        queryParams.lesson_type = searchParams.lesson_type;
      }
      if (searchParams.proficiency_level) {
        queryParams.proficiency_level = searchParams.proficiency_level;
      }
      if (searchParams.start_date) {
        queryParams.start_date = searchParams.start_date;
      }
    }

    try {
      const data = activeTab === 'court' ? await searchCourts(queryParams) : await searchLessons(queryParams);
      setSearchResults(data);
      const route = activeTab === 'court' ? '/court-results' : '/lesson-results';
      navigate(route);
    } catch (error) {
      console.error(`Error searching ${activeTab}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    setShowFilters(false); // Close filters when switching tabs
  };

  return (
    <div className="search-area">
      <h1 className="motto">KNOTTING YOUR TENNIS NETWORK.</h1>
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'court' ? 'active' : ''}`}
          onClick={() => handleTabSwitch('court')}
        >
          Book A Court
        </button>
        <button
          className={`tab ${activeTab === 'lesson' ? 'active' : ''}`}
          onClick={() => handleTabSwitch('lesson')}
        >
          Book A Group Lesson
        </button>
      </div>
      <form className="search-form" onSubmit={handleSearch}>
        {activeTab === 'court' && (
          <>
            <input
              type="text"
              name="cityZip"
              placeholder="City or ZIP code"
              className="input-field"
              onChange={handleInputChange}
              value={searchParams.cityZip}
            />
            <input
              type="date"
              name="date"
              className="input-field"
              onChange={handleInputChange}
              value={searchParams.date}
            />
            <select
              name="time_range"
              className="input-field"
              onChange={handleInputChange}
              value={searchParams.time_range}
            >
              <option value="">Select Time</option>
              <option value="early_morning">Early Morning 6:00 to 9:00</option>
              <option value="morning">Rise & Rally 9:00 to 12:00</option>
              <option value="afternoon">Mid-day Matchup 12:00 to 17:00</option>
              <option value="evening">Evening Rallies 17:00 to 23:00</option>
            </select>
            <button type="button" className="filter-btn" onClick={toggleFilters}>
              <FaFilter /> Filter
            </button>
          </>
        )}
        {activeTab === 'lesson' && (
          <>
            <input
              type="text"
              name="cityZip"
              placeholder="City or ZIP code"
              className="input-field"
              onChange={handleInputChange}
              value={searchParams.cityZip}
            />
            <select
              name="lesson_type"
              className="input-field"
              onChange={handleInputChange}
              value={searchParams.lesson_type}
            >
              <option value="">Lesson Type</option>
              <option value="group_adult">Adult</option>
              <option value="group_junior">Junior</option>
            </select>
            <input
              type="date"
              name="start_date"
              className="input-field"
              onChange={handleInputChange}
              value={searchParams.start_date}
              placeholder="Start Date (optional)"
            />
            <button type="button" className="filter-btn" onClick={toggleFilters}>
              <FaFilter /> Filter
            </button>
          </>
        )}
        <button type="submit" className="search-btn">Search</button>
      </form>
        <FilterModal
        show={showFilters}
        activeTab={activeTab}
        onClose={() => setShowFilters(false)}
        onApply={handleApplyFilter}
        />
      {loading && (
        <div className="loading-indicator">
          <FaSpinner className="spinner" /> 
          <div className="searching-text">Searching...</div>
        </div>
      )}
    </div>
  );
};

export default SearchArea;