import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createCourtBooking } from '../api/BookingApi/bookingApi';
import { initiateCheckout } from '../api/PaymentApi/paymentApi';
import { createLessonBooking } from '../api/BookingApi/bookingApi';

const PostLoginProcess = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const processPostLogin = async () => {
      const storedBookingData = sessionStorage.getItem('selectedBookingData');
      console.log(storedBookingData)
      if (storedBookingData) {
        const bookingData = JSON.parse(storedBookingData);
        if (bookingData.type === 'court') {
          await createAndInitiatePayment(bookingData, createCourtBooking);
        } else if (bookingData.type === 'lesson') {
          console.log(bookingData)
          await createAndInitiatePayment(bookingData, createLessonBooking);
        }
        sessionStorage.removeItem('selectedBookingData');
      } else {
        console.error('No booking data found');
        navigate('/no-booking-data');
      }
      setLoading(false);
    };

    processPostLogin();
  }, [navigate, location]);

  const createAndInitiatePayment = async (bookingData, bookingFunction) => {
    try {
      const bookingResponse = await bookingFunction(bookingData);
      if (bookingResponse && bookingResponse.id) {
        const checkoutResponse = await initiateCheckout(bookingData.type, bookingResponse.id);
        if (checkoutResponse && checkoutResponse.sessionId) {
          window.location.href = `https://checkout.stripe.com/pay/${checkoutResponse.sessionId}#fidkdWxOYHwnPyd1blpxYHZxWjA0SlVffHNMQXEzSlBQTzJVYUpERFBdfzVgSTFLVX12dm4wdnZvQDJpZkNUVW5KXEJ8SW4zT0s3f0NwN0JrN3xARkdmUkxhVjxUPXBub2tJS29sSGdDPDA9NTVHa3VcUFR0TicpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl`;
        } else {
          throw new Error('Failed to retrieve checkout session ID');
        }
      }
    } catch (error) {
      console.error('Error during booking process:', error);
      navigate('/booking-error');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Content after post-login process */}
    </div>
  );
};

export default PostLoginProcess;
