import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SearchProvider } from './context/SearchContext';
import { UserProvider } from './context/UserContext';
import HomePage from './pages/HomePage';
import CourtResults from './components/CourtResults';
import LessonResults from './components/LessonResults';
import ClubProfile from './components/ClubProfile';
import ClubAdminLogin from './components/ClubAdminLogin';
import ClubAdminLogout from './components/ClubAdminLogout';
import PostLoginProcess from './components/PostLoginProcess';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancel from './components/PaymentCancel';
import AdminDashboard from './components/AdminDashboard';
import AuthSuccess from './components/AuthSuccess';
import AuthSuccessAdmin from './components/AuthSuccessAdmin';
import UserLogout from './components/UserLogout';
import UserLogin from './components/UserLogin';

function App() {
  return (
    <Router>
      <Routes>
        {/* Wrap only specific routes with SearchProvider */}
        <Route path="/" element={
          <SearchProvider>
            <UserLogin />
          </SearchProvider>
        } />
        <Route path="/home" element={
          <SearchProvider>
            <HomePage />
          </SearchProvider>
        } />
        <Route path="/court-results" element={
          <SearchProvider>
            <CourtResults />
          </SearchProvider>
        } />
        <Route path="/lesson-results" element={
          <SearchProvider>
            <LessonResults />
          </SearchProvider>
        } />

        {/* Other routes not wrapped with SearchProvider */}
        <Route path="/admin" element={<ClubAdminLogin />} />
        <Route path="/admin-logout" element={<ClubAdminLogout />} />
        <Route path="/logout" element={<UserLogout />} />
        <Route path="/club-profile" element={<ClubProfile />} />
        <Route path="/post-login" element={<PostLoginProcess />} />
        <Route path="/payments/success" element={<PaymentSuccess />} />
        <Route path="/payments/cancel" element={<PaymentCancel />} />
        <Route path="/admin-dashboard/:courtId" element={<AdminDashboard />} />
        <Route path="/auth-success" element={<AuthSuccess />} />
        <Route path="/admin-auth-success" element={<AuthSuccessAdmin />} />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
