import React from 'react';
import CalendarDay from './CalendarDay';

const CourtCalendar = ({ calendarData }) => {
    // Group bookings by date for displaying
    const bookingsByDate = calendarData.reduce((acc, curr) => {
        const date = curr.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(curr);
        return acc;
    }, {});

    return (
        <div>
            {Object.entries(bookingsByDate).map(([date, bookings]) => (
                <CalendarDay key={date} date={date} bookings={bookings} />
            ))}
        </div>
    );
};

export default CourtCalendar;
