import axiosInstance from '../axiosInstance';

// create a lesson
export const createLesson = async (lessonData) => {
  try {
    const response = await axiosInstance.post('/club-management/lessons/', lessonData);
    return response.data;
  } catch (error) {
    console.error('Error creating lesson:', error);
  }
};

// list lessons
export const getLessons = async () => {
    try {
      const response = await axiosInstance.get('/club-management/lessons/');
      return response.data;
    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };

// update a lesson
export const updateLesson = async (lessonId, updatedData) => {
    try {
      const response = await axiosInstance.put(`/club-management/lessons/${lessonId}/`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating lesson:', error);
    }
  };

// delete a lesson
export const deleteLesson = async (lessonId) => {
    try {
      const response = await axiosInstance.delete(`/club-management/lessons/${lessonId}/`);
      return response.status;
    } catch (error) {
      console.error('Error deleting lesson:', error);
    }
  };

// patch a lesson
export const patchLesson = async (lessonId, updatedData) => {
    try {
      const response = await axiosInstance.patch(`/club-management/lessons/${lessonId}/`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating lesson:', error);
    }
  };