import axiosInstance from '../axiosInstance';

// initiates a checkout
export const initiateCheckout = async (bookingType, bookingId) => {
  try {
    const payload = {
      booking_type: bookingType,
    };

    // Add the appropriate booking ID based on the booking type
    if (bookingType === 'lesson') {
      payload.lesson_booking_id = bookingId;
    } else if (bookingType === 'court') {
      payload.court_booking_id = bookingId;
    }

    const response = await axiosInstance.post('/payments/checkout/', payload);
    return response.data;
  } catch (error) {
    console.error('Error initiating checkout:', error);
  }
};
